
@font-face {
	font-family: 'FontAwesome';
	src: url('/public/fonts/font-awesome/fontawesome-webfont.eot?v=4.7.0');
	src: url('/public/fonts/font-awesome/fontawesome-webfont.eot?#iefix&v=4.7.0') format('embedded-opentype'), 
		url('/public/fonts/font-awesome/fontawesome-webfont.woff2?v=4.7.0') format('woff2'), 
		url('/public/fonts/font-awesome/fontawesome-webfont.woff?v=4.7.0') format('woff'), 
		url('/public/fonts/font-awesome/fontawesome-webfont.ttf?v=4.7.0') format('truetype'), 
		url('/public/fonts/font-awesome/fontawesome-webfont.svg?v=4.7.0#fontawesomeregular') format('svg');
	font-weight: normal;
	font-style: normal;
  }
  .fa {
	  display: inline-block;
	  font: normal normal normal 14px/1 FontAwesome;
	  font-size: inherit;
	  text-rendering: auto;
	  -webkit-font-smoothing: antialiased;
	  -moz-osx-font-smoothing: grayscale;
  }
  .fa-bars:before{
	  content:"\f0c9"
  }
  .fa-times:before {
	content: "\f00d";
  }
  .fa-lg {
	  font-size: 3em;
	  line-height: 0.75em;
	  vertical-align: -15%;
  }

  
.menu, .menu-mobile {
	float: left;
	width: 100%;
	background: #f7f7f7;
	padding: 5px 0;
	box-shadow: 0 3px 4px 0 rgba(0,0,0,0.1);
	margin-bottom: 20px;
	position: fixed;
	left: 0;
	top: 0;
	z-index: 11;	
	height: 60px;
}

.menu .nav {
	float: right;
	padding: 10px 10px 10px 5px;
	margin-right: 20px;
}
.menu .logo, .menu-mobile .logo {
	float: left;
	padding: 10px 5px 10px 10px;
	margin-left: 90px;
}


/* Мобильная версия меню */
@media (max-width: 739px){
	.menu {
		display: none;
	}
}
/* Максимальный режим меню */
@media (min-width: 740px){
	.menu-mobile {
		display: none;
	}
	.menu, .menu-mobile {
		margin-bottom: 30px;
	}
}

/* Исчезновение / появление кнопки ХуторянинЪ */
@media (min-width: 740px) and (max-width: 850px) {	
	.menu .logo {
		display: none;
	}
}
@media (max-width: 480px){
	.menu-mobile {
		height: 60px;
	}
	.menu .logo, .menu-mobile .logo {
		display: none;
	}	
}



*{
	list-style: none;
	text-decoration: none;
}
.sidebar {
	position: fixed;
	left: -300px;
	top: 0;
	width: 0;
	height: 100%;
	background: #f1f1f1;
	z-index: 9;
	-webkit-transition: all .5s ease;
	-moz-transition: all .5s ease;
	-o-transition: all .5s ease;
	transition: all .5s ease;
}
.sidebar header {
	font-size: 22px;
	color: White;
	text-align: center;
	line-height: 70px;
	background: #e1e1e1;
	user-select: none;
	padding-left: 40px;
}
.sidebar ul a {
	display: block;
	width: 100%;
	height: 100%;
	line-height: 40px;
	font-size: 20px;
	padding-left: 40px;
	box-sizing: border-box;
	border-top: 1px solid rgba(255,255,255,.1);
	-webkit-transition: .4s;
	-moz-transition: .4s;
	-o-transition: .4s;
	transition: .4s;
}
.sidebar ul li:hover a {
	padding-left: 50px;
}
  
#check {
	display: none;
}
label #btn, label #cancel {
	position: absolute;
	cursor: pointer;
	border-radius: 3px;
}
label #btn {
	right: 20px;
	top: 12px;
	font-size: 50px;
	color: blue;
	padding: 5px 5px;
	-webkit-transition: all .5s;
	-moz-transition: all .5s;
	-o-transition: all .5s;
	transition: all .5s;
}
label #cancel {
	z-index: 10;
	left: -245px;
	top: 10px;
	font-size: 30px;
	color: #0a5275;
	padding: 4px 9px;
	opacity: 0;
	-webkit-transition: all .5s ease;
	-moz-transition: all .5s ease;
	-o-transition: all .5s ease;
	transition: all .5s ease;
}
#check:checked ~ .sidebar {
	left: 0;
	display: block;
	width: 300px;
}
#check:checked ~ label #cancel {
	opacity: 1;
	left: 245px;
}
  
label #fon {
	  position: fixed;
	  top: 0;
	  left: 0;
	  width: 100%;
	  height: 100%;
	  opacity: 0;
	  visibility: hidden;
	  background: rgb(0, 0, 0);
	  -webkit-transition: all .8s ease;
	  -moz-transition: all .8s ease;
	  -o-transition: all .8s ease;
	  transition: all .8s ease;
}
#check:checked ~ label #fon {
	  opacity: 0.5;
	  visibility: visible;
}

