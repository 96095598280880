
.Loader { 
    /* min-height: calc(100vh - var(--main-header-height) - var(--main-footer-height)); */
    display: flex;
    justify-content: center;
    align-items: center;
}

.Loader_normal {
    width: 48px;
    height: 48px;
    border: 5px solid grey;
    border-radius: 50%;
    border-bottom-color: transparent;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
}

.Loader_small {
    width: 24px;
    height: 24px;
    border: 3px solid grey;
    border-radius: 50%;
    border-bottom-color: transparent;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation_small 1s linear infinite;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
} 
@keyframes rotation_small {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
} 