
/* vanilla effect */
.contain {
	-webkit-flex: 1 1 auto; 
	-ms-flex:  1 1 auto; 
	flex: 1 1 auto;
	min-width: 220px;
	margin: 15px;
	text-align: center;
}
/* box - для товаров */
.contain .box {
	position: relative;
	width: 100%;
	height: 400px;
	background: #e1e1e1;
	border-radius: 20px;
	margin-bottom: 5px;
}
.contain .box::before {
	content: 'ХуторянинЪ';
	position: absolute;
	top: 10px;
	left: 5px;
	font-size: 2.1em;
	font-weight: 900; /* жирный текст */
	color: #000;/**/
	font-style: italic;
	opacity: 0;	/* видимость объекта откл */
	transition: 0.5s; /* задержка появления */
}
.contain .box::after {
	content: 'ХуторянинЪ';
	position: absolute;
	bottom: 10px;
	right: 5px;
	font-size: 2.1em;
	font-weight: 900; /* жирный текст */
	color: #000;/**/
	font-style: italic;
	opacity: 0;	/* видимость объекта откл */
	transition: 0.5s; /* задержка появления */
}
.contain .box:hover::before,
.contain .box:hover::after {
	opacity: 0.05; /* полупрозрачная видимость объекта */
}
.contain .box .name {
	position: absolute;
	top: 15px;
	left: 0;
	text-align: center;
	color: #fff;
	width: 100%;
	transform: translate3d(0,0,75px);/**/
	transition: 0.5s; /* задержка появления */
	z-index: 10;
}
.contain .box:hover .name {
	color: #000;
	top: 70px;
	background: #fff;
}
.price {
	position: absolute;
	bottom: 50px;
	left: 0;
	text-align: center;
	color: rgb(255, 0, 0);
	width: 100%;
	transition: 0.5s;
	z-index: 10;
}
.contain .box:hover .price {
	bottom: 20px;
}
.contain .box .buy {
	position: absolute;
	bottom: 5px;
	left: 50%;
	transform: translate3d(-50%,0,75px);
	color: #fff;
	padding: 10px 15px;
	text-decoration: none;
	transition: 0.5s; /* задержка появления */
	z-index: 10;
}
.contain .box:hover .buy {
	bottom: 70px;
}
.contain .box .product {
	position: absolute;
	top: 50%;
	left: 50%;
	max-width: 280px;
	transition: 0.5s;
	z-index: 8;
	transition: 0.5s;
	transform-style: preserve-3d;
	transform: translate3d(-50%,-50%,0px); /* rotate(-5deg);  rotate - наклон */
}
.contain .box:hover .product {
	transform: translate3d(-50%,-50%,70px) rotate(-3deg);
}	
/* --------------- */



/* ------------------------- */
/* раздел ПОЛЬЗА (или выгода)*/
.benefit {
	position: relative;
	padding-top: 30px;
	border-top: 1px solid lightgrey;
	margin: 50px 10px 50px 10px;
	width: 100%;
	height: 550px;
}
.benefit h2{
	text-align: center;
}
.benefit div {
	position: absolute;
	width: 40%;
}
.benefit div img{
	width: 100%;
}
.benefit_img1 {	
	left: 50px; 
	top: 150px;
}
.benefit_img2 {
	top: 270px;
	right: 50px;
}
.benefit_img3 {
	top: 400px;
	left: 100px;
}
.benefit_img_text {
	margin-top: -5px;
	font-size: 1em;
	text-align: center;
}
.benefit_text_bottom {
	position: absolute;
	top: 600px;
	right: 150px;
}
@media (max-width: 500px){
	.benefit_text_top {
		font-size: 1.2em;
	}
	.benefit_text_bottom {
		font-size: 0.8em;
		right: 50px;
	}
	.benefit_img_text {
		font-size: 0.8em;
	}
}
/* ------------------------- */


/* ------------------------- */
.help {
	position: relative;
	padding-top: 30px;
	border-top: 1px solid lightgrey;
	margin: 100px 10px 50px 10px;
	width: 100%;
	height: 500px;
	text-align: center;
}
@media (max-width: 700px){
	.help {
		height: 550px;
	}
}
/* ------------------------- */


/* ------------------------- */
/* Формирование заказа */
.creating_an_order {
	width: 100%;
}
.creating_an_order textarea {
	height: 100px;
}

/* ------------------------- */


/* ------------------------- */
/* АКЦИИ */
.r {
	color: red;
}
.g {
	color: rgb(5, 196, 5);
}
.b {
	color:black;
}
.bb {
	color:blue;
}

/* ------------------------- */
