
.Repair {
    /* width:100vw; */
    /* height: calc(100vh - var(--main-header-height) - var(--main-footer-height)); */
    background-color: lightgrey;
    /* background-color: green; */
    display: flex;    
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

.Repair_maxHeight {
    min-height: calc(100vh - var(--main-header-height) - var(--main-footer-height));
}

.Repair_box {
    background-color: #f3f3f3;
    display: flex;
    flex-direction: column;
    width: 500px;
    padding:5px;
    border-radius: 10px;
}

.Repair_boxTitle {
    text-align: center;
    padding:5px;
}

.Repair_boxBody {
    text-align: center;
    padding:5px;
}
