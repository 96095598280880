
.InfoPage {
    width: 100%;
}

ul {
	list-style-type: circle;
}

.InfoPage_title {
    padding: 50px 0;
}

.InfoPage_imageBox {
    display: flex;
	flex-direction: column;
	align-items: center;
}

.InfoPage_imageBox_image {
	position: relative;
	text-align: center;
	margin: 0 20px;		
}

.InfoPage_imageBox_image img {
	min-width: 220px;
	width: 100%;
}

.InfoPage_imageBox_image_img33 {
	width: calc(33% - 10px);
	padding: 0 5px 0 5px;
}
.InfoPage_imageBox_image_img50 {
	width: calc(50% - 15px);
	padding: 0 7.5px 0 7.5px;
}
.InfoPage_imageBox_image_img75 {
	width: calc(75% - 17px);
	padding: 0 8.5px 0 8.5px;
}
.InfoPage_imageBox_image_img100 {
	width: calc(100% - 20px);
	padding: 0 10px 0 10px;
}

.InfoPage_imageBox_image_caption {
	color: white;
	text-shadow: red 0 0 3px;
	position: absolute;
	top: 10px;
	right: 10px;
	z-index: 10;
}
.InfoPage_imageBox_image_caption-two {
	color: white;
	text-shadow: green 0 0 5px;
	position: absolute;
	bottom: 10px;
	left: 20px;
	z-index: 10;
}

.InfoPage_imageBox_aboutBox {
	margin-top: -60px;
}

.InfoPage_imageBox_aboutBox_aboutBtn {
	position: relative;
    cursor: pointer;
	border: 1px solid #333;
	color: #333;
	padding: 5px 30px;	
	border-radius: 5px;	
	margin: 1px 0;	
	display: inline-flex;	
	line-height: 0.5;
}

.InfoPage_imageBox_aboutBox_aboutText {
    margin: 0 50px;
    text-align: left;
}


@media (min-width: 501px) and (max-width: 770px){
	.InfoPage_imageBox_aboutBox {
		margin-top: -55px;
	}
	.InfoPage_imageBox_aboutBox_aboutBtn {
		font-size: 12px;
	}
	.InfoPage_imageBox_aboutBox_aboutBtn h4 {
		font-size: 12px;
	}
	.InfoPage_imageBox_image_caption,
	.InfoPage_imageBox_image_caption-two {
		font-size: 18px;
	}
    .InfoPage_imageBox_aboutBox_aboutText {
        margin: 0 20px;
    }
}

@media (max-width: 500px){
	.InfoPage_imageBox_aboutBox {
		margin-top: -50px;
	}
	.InfoPage_imageBox_aboutBox_aboutBtn {
		font-size: 10px;
	}
	.InfoPage_imageBox_image_caption,
	.InfoPage_imageBox_image_caption-two {
		font-size: 16px;
	}
    .InfoPage_imageBox_aboutBox_aboutText {
        margin: 0 10px;
    }
}
