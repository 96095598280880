
.MyModal {}


.MyModal_backmodal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: lightgray;
    opacity: 0.5;
    z-index: 10000;
}
.MyModal_modal {
    position: fixed;
    width: 400px;
    height: 350px;
    left: calc(50vw - 200px); /* 200px - половина width */
    top: calc(50vh - 150px); /* 150px - половина height */
    background: white;
    border: 1px grey solid;
    border-radius: 20px;
    box-shadow: 0 0 10px rgba(0,0,0,0.5);
    z-index: 10001;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.MyModal_modal header {
    padding-top: 10px;
    font-size: 2rem;
}

.MyModal_modal main {
    background-color: transparent;
}

.MyModal_modal footer {
    /* position: absolute;
    bottom: 0; */
    text-align: center;
    width: 100%;
    padding-bottom: 10px;
    /* background-color: green; */
}


