
.UserPage {
    min-height: calc(100vh - var(--main-header-height) - var(--main-footer-height));
}

.UserPage tr td,
.UserPage tr th {
    padding: 5px 10px;
}


