
.PublicationPage {}

.PublicationPage tr td,
.PublicationPage tr th {
    padding: 5px 10px;
}

.fileReaderImage {
    max-height: 200px;
    max-width: 200px;
}

.PublicationPage img {
    width: 500px;
}

.PublicationData table,
.PublicationData th,
.PublicationData td {
    border-collapse: collapse;
    border: 1px dashed black; 
}

.PublicationData td {
    max-width: 400px;
}

.PublicationPage_pub_body_width {
    max-width: 800px;
    margin:auto;
    border: 1px solid grey;
    box-shadow: 0 0 10px rgba(0,0,0,0.5);
    background: rgb(224, 224, 224);
    padding: 15px;
}


@media (max-width: 768px) {	
    .PublicationPage img {
        width: 300px;
    }
    .PublicationData td {
        max-width: 250px;
    }
    
}
