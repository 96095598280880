
.App {
    width: 100%;
}

.App_AppRouter {
    /* min-height: calc(100vh - var(--main-header-height) - var(--main-footer-height)); */
}

.globalStylePage {
    /* min-height: calc(100vh - var(--main-header-height) - var(--main-footer-height)); */
    /* background-color: lightgrey; */
}

.globalStylePage_title {
	background: #f7f7f7;
    /* background-color: green; */
	text-align: center;
	padding: 60px 10px 20px 10px;
}

.heightBody {  
    min-height: calc(100vh - var(--main-header-height) - var(--main-footer-height));
}